import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadarController,
    RadialLinearScale,
    PointElement,
    LineElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadarController,
    RadialLinearScale,
    PointElement,
    LineElement
);


const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
const HorizontalBarChart = ({ skills }) => {
    const data = {
        labels: skills.map(skill => skill.name),
        datasets: [
            {
                label: 'Growth',
                data: skills.map(skill => skill.popularity),
                backgroundColor: skills.map(() => generateRandomColor()),
                borderColor: skills.map(() => '#000'),
                borderWidth: 1,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                font: {
                    size: 2,
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.dataset.label}: ${context.raw}%`;
                    },
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        indexAxis: 'y',
    };

    return (
        <div>
            <h2 style={{ textAlign: "center", color: "#333" }}>Skill Growth</h2>
            <Bar data={data} options={options} />
        </div>
    );
};



export default HorizontalBarChart;
import React from "react";
import { View, Text, StyleSheet, Link } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  section: {
    padding: 4,
    backgroundColor: "#FFFFFF",
    borderRadius: 2,
  },
  cardContainer: {
    marginBottom: 4,
  },
  card: {
    padding: 2,
    backgroundColor: "#F3F4F6",
    borderRadius: 8,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 4,
  },
  projectName: {
    fontSize: 10,
    fontWeight: "600",
    color: "#007BFF",
  },
  descriptionHeader: {
    fontSize: 10,
    fontWeight: "200",
    marginTop: 4,
    color: "#6B7280",
  },
  descriptionText: {
    fontSize: 10,
    color: "#4B5563",
    marginTop: 4,
  },
  linkContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 4,
  },
  linkText: {
    fontSize: 10,
    color: "#007BFF",
  },
  newHeader: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 8,
  },
});

const ProjectCard = ({ project }) => {
  return (
    <View style={styles.card}>
      <View style={styles.newHeader}>
        <Text style={styles.projectName}>{project.name}</Text>
      </View>
      <Text style={styles.descriptionHeader}>Project Objective</Text>
      <Text style={styles.descriptionText}>{project.description}</Text>

      <View style={styles.linkContainer}>
        <View>
          <Text style={styles.descriptionHeader}>Live Link</Text>
          <Link src={project.liveLink} style={styles.linkText}>
            {project.liveLink}
          </Link>
        </View>
      </View>
    </View>
  );
};

const ProjectSection = ({ projects }) => {
  return (
    <View style={styles.section}>
      <Text style={styles.newHeader}>Projects</Text>
      {projects.map((project, index) => (
        <View key={index} style={styles.cardContainer}>
          <ProjectCard project={project} />
        </View>
      ))}
    </View>
  );
};

export default ProjectSection;

import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  section: {
    marginBottom: 2,
    padding: 4,
    backgroundColor: "#FFFFFF",
    borderRadius: 2,
  },
  subHeader: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    fontWeight: "600",
    color: "#333333",
    marginBottom: 10,
  },
  list: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 3,
  },
  listItem: {
    fontSize: 10,
    backgroundColor: "#E3F7FF",
    color: "#007BFF",
    padding: "4px 8px",
    borderRadius: 5,
    margin: 2
  },
});

const SkillsSection = ({ candidateData }) => {
  return (
    <View style={styles.section}>
      <Text style={styles.subHeader}>Skills</Text>
      <View style={styles.list}>
        {candidateData?.skills?.length === 0 ? (
          <Text style={styles.listItem}>No skills provided</Text>
        ) : (
          candidateData?.skills?.map((skill, index) => (
            <Text key={index} style={styles.listItem}>
              {skill}
            </Text>
          ))
        )}
      </View>
    </View>
  );
};

export default SkillsSection;

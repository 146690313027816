import { useEffect, useRef, useState } from "react";
import { BTN_ADD, SKILLS } from "utils/constants";
import { IoMdClose } from "react-icons/io";
import { patchSkills } from "components/Fetchers";
import { MdOutlineDone } from "react-icons/md";
import { Alert, Snackbar } from "@mui/material";
import { MdDelete } from "react-icons/md";
import ToastMsg from "./ToastMsg";
import { useSelector, useDispatch } from "react-redux";
import { MdDone } from "react-icons/md";
import { setProfileSkills } from "utils/skillSlice";
const SkillsSection = ({ candidateData }) => {
  const [skillValue, setSkillValue] = useState("");
  const [skills, setSkills] = useState(candidateData?.skills || []);
  const [deleteOpt, setDeleteOpt] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [isDeleteToast, setIsDeleteToast] = useState(false);
  const [hideBtn, setBtnHide] = useState(false);
  const inputRef = useRef(null);
  const isCandidate = useSelector((store) => store?.candidate?.isCandidate);
  const isBtnDisabled = useSelector((store) => store?.btns?.isBtnDisabled);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setProfileSkills(skills));
  }, [skills])
  useEffect(() => {
    const keyPressHandler = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        addNewSkills();
      }
    };
    const input = inputRef.current;
    if (input) {
      input.addEventListener("keypress", keyPressHandler);
    }
    return () => {
      if (input) {
        input.removeEventListener("keypress", keyPressHandler);
      }
    };
  }, [skills, skillValue]);
  const submitSkills = async (updatedSkills) => {
    try {
      dispatch(setProfileSkills(updatedSkills));
      await patchSkills(candidateData._id, updatedSkills);
      setIsToast(true);
    } catch (error) {
      console.error("Error saving skills:", error);
    }
  };
  const addNewSkills = async () => {
    if (skillValue) {
      const updatedSkills = [...skills, skillValue];
      setSkills(updatedSkills);
      await submitSkills(updatedSkills);
      setSkillValue("");
    }
  };
  const handleDeleteSkills = async (skillToDelete) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToDelete);
    setSkills(updatedSkills);
    await submitSkills(updatedSkills);
    setIsDeleteToast(true);
  };
  const toggleOptions = () => {
    setDeleteOpt(true);
  };
  const handleKeyPress = (e) => {
    if (inputRef.current.value.trim() === "") {
      setBtnHide(false);
    } else if (/^[a-zA-Z]$/.test(e.key)) {
      setBtnHide(true);
    }
  };
  const handleInputChange = (e) => {
    setSkillValue(e.target.value);
    if (e.target.value.trim() === "") {
      setBtnHide(false);
    } else {
      setBtnHide(true);
    }
  };
  const toggleDone = () => {
    setDeleteOpt(false);
  };
  return (
    <div className="bg-white p-6 rounded-md min-w-[50%] md:max-w-[98%]">
      <div className="flex justify-center ">
        <h2 className="text-sm md:text-xl font-bold">{SKILLS}</h2>
      </div>
      <div className="flex flex-col md:flex md:flex-row justify-between items-center">
        {!isBtnDisabled && isCandidate && <div className="w-full flex items-center justify-between mt-4">
          <input
            ref={inputRef}
            type="text"
            className="bg-[#DBE9FE] flex-grow py-2 mr-2 bg-slate-200 border-none font-bold text-blue-500 rounded-md placeholder-[#4B5563] font-mono text-center"
            value={skillValue}
            placeholder="Add Skills.."
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
          />
          {hideBtn && (
            <MdDone onClick={addNewSkills} />
          )}
          {/* <button
            className="w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
          >
            ADD SKILLS
          </button> */}
          {!deleteOpt ? (
            <MdDelete
              className="ml-2 cursor-pointer text-gray-500"
              onClick={toggleOptions}
            />
          ) : (
            <MdOutlineDone
              className="ml-2 cursor-pointer text-gray-500"
              onClick={toggleDone}
            />
          )}
        </div>
        }
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        {skills.length === 0 ? (
          <p className="text-gray-500 mt-2">No skills added yet.</p>
        ) : (
          skills.map((skill, index) => (
            <span
              key={index}
              className="px-4 py-2 bg-blue-100 text-blue-600 rounded-md flex items-center"
            >
              {skill}
              {deleteOpt && (
                <IoMdClose
                  onClick={() => handleDeleteSkills(skill)}
                  className="text-gray-500 ml-1 cursor-pointer"
                />
              )}
            </span>
          ))
        )}
      </div>
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="Skills added successfully!"
        severity="success"
      />
      <ToastMsg
        isToast={isDeleteToast}
        setIsToast={setIsDeleteToast}
        data="Skills deleted successfully!"
        severity="error"
      />
    </div>
  );
};
export default SkillsSection;
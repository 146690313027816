import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import SearchMenu from "./SearchMenu";
import leftVector from "../../images/components/left-vector.svg";
import rightVector from "../../images/components/right-vector.svg";
import landingVector from "../../images/Landing/landingVecot.png";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Snowfall from 'react-snowfall'
import xmasCap from "../../images/xmas1.png"
const baseUrl = process.env.REACT_APP_API__URL;

const HeroSection = ({ jobs }) => {
  const [counter, setCounters] = useState([]);
  console.log(counter);

  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from(".headingTag", {
      x: -200,
      opacity: 0,
      duration: 1,
      ease: "power3.out",
    });

    tl.from(".landingVector", {
      y: 100,
      opacity: 0,
      duration: 1,
      delay: 0.2,
      ease: "back.out(1.7)",
      scale: 0.95,
    });
  });

  useEffect(() => {
    fetchCounters();
  }, []);

  const fetchCounters = async () => {
    const data = await fetch(`${baseUrl}/api/count`);
    const jsonData = await data.json();
    console.log(jsonData);
    setCounters(jsonData);
  };

  return (
    <>
      <Snowfall />
      <div className="bg-landing min-h-screen flex justify-center items-center">
        <div className="w-full max-w-7xl flex flex-col md:flex-row justify-between items-center px-4 py-8">
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left space-y-6">
            <div className="heading z-10 text-4xl md:text-6xl">
              <img src={xmasCap} className="relative w-24 h-24 right-10 top-[60px]" />
              <div className="headingTag font-semibold text-white">
                Job Matchmaker
              </div>
              <div className="headingTag font-black text-white">
                for Tech Talent
              </div>
            </div>
            <p className="paragraph text-white text-sm md:text-base mb-8">
              Techies! get hired smartly. Our AI deeptech will increase your
              visibility by data matchmaking, which means a fair screening
              process and getting hired for the skills relevant to the job.
            </p>
            <div className="w-[80%]">
              <SearchMenu jobs={jobs} />
            </div>
            <div className="w-full flex gap-6 md:gap-8 justify-center md:justify-start mt-8">
              {counter.map((counter, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-center md:items-start justify-evenly"
                  >
                    <div className="text-2xl font-bold text-blue-600">
                      <CountUp
                        start={0}
                        end={counter.number}
                        duration={0.5}
                        className="inline"
                      />
                      <span className="text-blue-600">+</span>
                    </div>
                    <div className="text-sm font-normal text-white">
                      {counter.title}
                    </div>
                  </div>
                );
              })}

            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-center items-center mt-8 md:mt-0">
            <img
              className="landingVector w-[80%] md:w-[85%]"
              src={landingVector}
              alt="landing-vector"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;

import React from "react";
import axios from "axios";
import "./style.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Ctable from "../Homepage/Ctable";
import { useAuth } from "utils/auth";
import { Snackbar, MuiAlert, Alert } from "components/MUI";
import { useState } from "react";
import SocialShare from "components/SocialMedia/SocialShare";
import fallback_image from "../../images/fallback_image.png";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const data = {
//     "_id": "62514e6b974489288798fda7",
//     "jobId": 1,
//     "companyid": 2,
//     "jobDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vehicula dictum suscipit. Suspendisse potenti. Etiam metus sem, porttitor id eros quis, sollicitudin ultricies enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus scelerisque, nunc eu convallis fermentum, nibh tortor imperdiet lectus, a suscipit felis est ac quam. Sed vitae aliquam risus. Suspendisse at eros sit amet mauris accumsan volutpat sed sit amet velit",
//     "companyname": "Ferreri",
//     "aboutcompany": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vehicula dictum suscipit. Suspendisse potenti. Etiam metus sem, porttitor id eros quis, sollicitudin ultricies enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus scelerisque, nunc eu convallis fermentum, nibh tortor imperdiet lectus, a suscipit felis est ac quam. Sed vitae aliquam risus.",
//     "educationnicetohave": ["BTech"],
//     "educationmusthave": ["BTech"],
//     "companytype": "Automation",
//     "companysize": "Large",
//     "companydescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vehicula dictum suscipit. Suspendisse potenti. Etiam metus sem, porttitor id eros quis, sollicitudin ultricies enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus scelerisque, nunc eu convallis fermentum, nibh tortor imperdiet lectus, a suscipit felis est ac quam. Sed vitae aliquam risus. Suspendisse at eros sit amet mauris accumsan volutpat sed sit amet velit",
//     "cintrovediolink": "https://www.youtube.com/watch?v=5nHN1AYdJ7c",
//     "cheadoffice": "California",
//     "jobTitle": "Robotics",
//     "location": "Usa",
//     "typeofwork": "Offline",
//     "skills": ["JavaScript", "C++", "Java", "Python", "HTML", "CSS"],
//     "nicetohaveskills": ["JavaScript", "C++", "Java", "Python"],
//     "musthaveskills": ["JavaScript", "C++", "Java", "Python"],
//     "numofapplicants": 676,
//     "createdAt": "2022-04-09T09:14:19.277Z",
//     "updatedAt": "2022-04-09T09:14:19.277Z",
//     "__v": 0
// }

function Jobslist({
  job,
  handleApplyAfterEffect,
  candidateSkills,
  candidateData,
}) {
  const auth = useAuth();
  const jobId = job._id;

  const shareUrl = `${window.origin}/jobpost/${jobId}`;
  if (candidateSkills) {
    const totalSkills = [...job.mustHaveSkills, ...job.goodToHaveSkills];
    const lowerCaseSkills = totalSkills.map((skill) => {
      return skill.toLowerCase();
    });
    const lowerCaseCandidateSkills = candidateSkills.map((skill) => {
      return skill.toLowerCase();
    });

    //get only unique skills
    const lowerCaseUniqueTotalSkills = [...new Set(lowerCaseSkills)];
    // console.log("uniqueSkills")
    const lowerCaseUniqueCandidateSkills = [
      ...new Set(lowerCaseCandidateSkills),
    ];

    // console.log("lowerCaseSkills")
    // console.log(lowerCaseUniqueTotalSkills)
    // console.log("lowerCaseCandidateSkills")
    // console.log(lowerCaseUniqueCandidateSkills)

    const isSkillMatched = lowerCaseUniqueTotalSkills?.some((skill) => {
      return lowerCaseUniqueCandidateSkills?.includes(skill);
    });
    // console.log("isSkillMatched")
    // console.log(isSkillMatched)
    const matchedSkillsLength = lowerCaseUniqueTotalSkills?.filter((skill) => {
      return lowerCaseUniqueCandidateSkills?.includes(skill);
    }).length;

    //check the percentage of skills matched
    const percentage = Math.floor(
      (matchedSkillsLength / lowerCaseUniqueTotalSkills.length) * 100
    );

    // console.log(matchedSkillsLength)
    if (isSkillMatched) {
      var match = (
        <Box
          sx={{
            backgroundColor: "rgb(187 247 208)",
            color: "rgb(54 83 20)",
            textAlign: "center",
            padding: "0.5rem",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <p style={{ margin: "0", width: "max-content" }}>
            {percentage}% Skills&nbsp;
          </p>
          <p style={{ margin: "0", width: "max-content" }}>Matched</p>
        </Box>
      );
    } else {
      var match = <></>;
    }
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [errorOpen, setErrorOpen] = useState(false);

  const onError = () => {
    setErrorOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  const handleApply = (e) => {
    e.preventDefault();
    console.log(auth);
    if (
      (auth.candidateDone == false || auth.candidateDone == "false") &&
      candidateData.resume == null
    ) {
      onError();
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/candidate/jobapply`, {
        jobId: job._id,
        status: "Applied",
      })
      .then((res) => {
        handleApplyAfterEffect(job._id, true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        handleApplyAfterEffect(job._id, false);
      });
    console.log(job._id);
  };

  // console.log(job)

  return (
    <>
      <Accordion
        sx={{
          marginBottom: "30px",
          border: "0.881319px solid #9C9C9C",
          borderRadius: "8.81319px 8.81319px 8.81px 8.81px !important",
          boxShadow: "0px 2.64396px 4.40659px rgba(0, 0, 0, 0.25)",
          fontFamily: "outfit",
        }}
      >
        <AccordionSummary
          expandIcon={
            <p>
              <ExpandMoreIcon
                style={{ marginTop: "auto", marginBottom: "auto" }}
              />
            </p>
          }
          // aria-control
          s="panel1a-content"
          id="panel1a-header"
        >
          <div className="featured__jobDetails">
            <img src={job.company?.photo ? job.company?.photo : fallback_image} alt="" className="jobDetails__logo" />

            <div className="jobDetails__info">
              <h2
                className="info__title"
                style={{
                  fontWeight: "700",
                  fontSize: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "25px",
                    xl: "25px",
                  },
                }}
              >
                {job.title}
              </h2>
              <p
                className="info__companyname"
                style={{
                  fontWeight: "300",
                  fontSize: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "25px",
                    xl: "25px",
                  },
                }}
              >
                {job.company?.companyName}
              </p>
              <div className="info__details">{job.location}</div>
            </div>

            <Box sx={{ margin: "0.7rem" }}>{match}</Box>

            <div
              className="jobDetails__main"
              style={{ fontSize: "16px", color: "#5352ED" }}
            >
              {/* {job.modeofwork} | */}
              <p> {job.type}</p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: "1px solid grey" }}>
          <Box sx={{ width: "100%", margin: "Auto" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="featured__tabs"
              >
                <Tab label="Must Have" {...a11yProps(0)} />
                <Tab label="Good to Have" {...a11yProps(1)} />
                <Tab label="More" {...a11yProps(2)} />
                <Tab label="Company" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="featured__tabpanel">
              {/* edu={job.educationmusthave} */}

              <Ctable
                skill={job.mustHaveSkills}
                data={job}
                candidateSkills={candidateSkills}
              />
              {/* <div>{shareUrl}</div> */}
              <Box
                sx={{
                  marginLeft: "1rem",
                  display: "flex",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ my: "auto", marginRight: "0.7rem" }}
                >
                  Share this job:
                </Typography>
                <SocialShare shareUrl={shareUrl} job={job} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="featured__tabpanel">
              {/* edu={job.educationnicetohave} */}

              <Ctable
                skill={job.goodToHaveSkills}
                data={job}
                candidateSkills={candidateSkills}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h3>Job Description</h3>
              <p
                style={{
                  backgroundColor: "white",
                  padding: "1rem",
                  margin: "0px",
                  textAlign: "start",
                  whiteSpace: "break-spaces",
                }}
              >
                {job.description}
              </p>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <h3>About Company</h3>
              {job.company?.about}
              <h3>Video Link</h3>
              <p>{job.company?.aboutCompany?.video}</p>
              <h3>Website</h3>
              <p>{job.company?.aboutCompany?.website}</p>
            </TabPanel>
          </Box>
          <button className="featured__button" onClick={handleApply}>
            Apply Now
          </button>
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Please Complete the Profile
        </Alert>
      </Snackbar>
    </>
  );
}

export default Jobslist;

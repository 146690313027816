import React, { useState } from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import profileAvatar from "../../../images/avatar.png";
import badge1 from "../../../images/badge1.png";
import badge2 from "../../../images/badge2.png";
import badge3 from "../../../images/badge3.png";
import leetcode1 from "../../../images/leetcode1.png";
import leetcode2 from "../../../images/leetcode2.png";
import leetcode3 from "../../../images/leetcode3.png";
import ProfileBadgesPDF from "./ProfileBadgesPDF";
import SkillsSection from "./SkillsSection";
import EducationSection from "./EducationSection";

const leetcode = [
  { img: leetcode1, count: "06", title: "Gold" },
  { img: leetcode2, count: "15", title: "Silver" },
  { img: leetcode3, count: "19", title: "Bronze" },
];

const stackoverBadge = [
  { img: badge1, count: "01", title: "Gold" },
  { img: badge2, count: "15", title: "Silver" },
  { img: badge3, count: "12", title: "Bronze" },
];

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: 4,
    backgroundColor: "white",
    borderRadius: 8,
    padding: 2,
    width: "100%",
  },
  leftColumn: {
    width: "100%",
    alignItems: "start",
    justifyContent: "start",
    paddingBottom: 4,
    marginBottom: 2,
  },
  profileImageContainer: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    overflow: "hidden",
    border: "4px solid #1E40AF",
    marginBottom: 10,
  },
  profileImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  profileText: {
    fontSize: 12,
    color: "#333",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    marginBottom: 4,
  },
  profileJob: {
    fontSize: 10,
    color: "#333",
    textAlign: "center",
    backgroundColor: "#EFF6FF",
    marginBottom: 4,
    padding: 4,
    borderRadius: 4,
  },
  profileInfo: {
    marginTop: 8,
    textAlign: "start",
  },
  bulletPoint: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6,
  },
  bullet: {
    width: 3,
    height: 3,
    backgroundColor: "#333",
    borderRadius: "50%",
    marginRight: 10,
  },
  badgeSection: {
    marginTop: 5,
  },
});

const SideBar = ({ candidateData }) => {
  const [userProfile] = useState(candidateData?.profile?.url || profileAvatar);

  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        <Text style={styles.profileText}>{candidateData?.name}</Text>
        <Text style={styles.profileJob}>{candidateData?.jobPosition}</Text>
        <View style={styles.profileInfo}>
          {candidateData?.contactInfo?.address && (
            <View style={styles.bulletPoint}>
              <View style={styles.bullet} />
              <Text style={styles.profileJob}>
                {candidateData?.contactInfo?.address}
              </Text>
            </View>
          )}
          {candidateData?.contactInfo?.exp && (
            <View style={styles.bulletPoint}>
              <View style={styles.bullet} />
              <Text style={styles.profileJob}>
                {candidateData?.contactInfo?.exp}
              </Text>
            </View>
          )}
          {candidateData?.contactInfo?.languages && (
            <View style={styles.bulletPoint}>
              <View style={styles.bullet} />
              <Text style={styles.profileJob}>
                {candidateData?.contactInfo?.languages}
              </Text>
            </View>
          )}
          {candidateData?.email && (
            <View style={styles.bulletPoint}>
              <View style={styles.bullet} />
              <Text style={styles.profileJob}>{candidateData?.email}</Text>
            </View>
          )}
          {candidateData?.contactInfo?.phone && (
            <View style={styles.bulletPoint}>
              <View style={styles.bullet} />
              <Text style={styles.profileJob}>
                {candidateData?.contactInfo?.phone}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.rightColumn}>
        <View style={styles.badgeSection}>
          <SkillsSection candidateData={candidateData} />
          <EducationSection education={candidateData?.education} />
        </View>
      </View>
    </View>
  );
};

export default SideBar;

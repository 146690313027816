import { createSlice } from "@reduxjs/toolkit";
const resumeSlice = createSlice({
    name: "resumeKeys",
    initialState: {
        isUpdated: {},
    },
    reducers: {
        setResumeUpdate: (state, action) => {
            state.isUpdated = action.payload;
        },
    },
});
export const { setResumeUpdate } = resumeSlice.actions;
export default resumeSlice.reducer;
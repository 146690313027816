import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  section: {
    marginBottom: 8,
    padding: 4,
    backgroundColor: "#FFFFFF",
    borderRadius: 2,
  },
  timelineItem: {
    position: "relative",
    marginBottom: 8,
    flexDirection: "row",
    width: "100%",
  },
  card: {
    padding: 2,
    backgroundColor: "#F3F4F6",
    borderRadius: 8,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#1D4ED8",
  },
  jobTitle: {
    fontSize: 10,
    color: "#6B7280",
    marginTop: 4,
  },
  dateLocationType: {
    flexDirection: "row",
    marginTop: 8,
    fontSize: 10,
    color: "#6B7280",
  },
  description: {
    marginTop: 10,
    fontSize: 10,
    color: "#6B7280",
    // color: "#374151",
  },
  newHeader: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    marginBottom: 8,
  },
});

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short" };
  return new Date(dateString).toLocaleString("en-US", options);
};

const WorkExperienceSection = ({ workExperience }) => {
  return (
    <View style={styles.section}>
      <Text style={styles.newHeader}>Work Experience</Text>
      {workExperience.map((work, index) => {
        const formattedStartDate = formatDate(work.startDate);
        const formattedEndDate = formatDate(work.endDate);

        return (
          <View key={index} style={styles.timelineItem}>
            <View style={styles.card}>
              <Text style={styles.header}>{work.companyName}</Text>
              <Text style={styles.jobTitle}>{work.jobTitle}</Text>
              <View style={styles.dateLocationType}>
                <Text>{`${formattedStartDate} - ${formattedEndDate}`}</Text>
                <Text style={{ marginLeft: 10 }}>
                  {work.location || "Remote"}
                </Text>
                <Text style={{ marginLeft: 10 }}>
                  {work.jobType || "Full-time"}
                </Text>
              </View>
              <Text style={styles.description}>{work.description}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default WorkExperienceSection;

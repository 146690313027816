import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel with your project token
if(window.location.href.includes('workcrew')) {
  mixpanel.init("551475659c843c53c6add10fd1adcf36", {
    debug: true,
    track_pageview: true
  });
} else {
  mixpanel.init("c0c36d0401516c3f3dbeb339f56c62ad", {
    debug: true,
    track_pageview: true
  });
}


export default mixpanel;

import React from "react";
import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import ed1 from "../../../images/ed1.png";

const styles = StyleSheet.create({
  section: {
    marginBottom: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 2,
    marginTop: 4,
    padding: 4,
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#F3F4F6",
    borderRadius: 8,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: 4,
    marginTop: 4,
  },
  imageContainer: {
    marginRight: 16,
  },
  collegeLogo: {
    width: 38,
    height: 38,
    borderRadius: "50%",
  },
  content: {
    flex: 1,
  },
  collegeName: {
    fontSize: 10,
    fontWeight: "600",
    color: "#1D4ED8",
  },
  courseName: {
    fontSize: 10,
    color: "#6B7280",
  },
  cgpa: {
    fontSize: 10,
    color: "#4B5563",
  },
  date: {
    fontSize: 10,
    color: "#6B7280",
    marginTop: 4,
  },
  editButtonContainer: {
    marginLeft: 10,
    cursor: "pointer",
  },
  editButtonImage: {
    width: 24,
    height: 24,
  },
  header: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    marginBottom: 4,
  },
});

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short" };
  return new Date(dateString).toLocaleString("en-US", options);
};

const EducationCard = ({
  edu,
  editImg,
  isCandidate,
  isBtnDisabled,
  handleEdit,
}) => {
  const formattedStartDate = formatDate(edu.startDate);
  const formattedEndDate = formatDate(edu.endDate);

  return (
    <View style={styles.card}>
      <View style={styles.imageContainer}>
        <Image src={ed1} style={styles.collegeLogo} />
      </View>
      <View style={styles.content}>
        <Text style={styles.collegeName}>{edu.collegeName}</Text>
        <Text style={styles.courseName}>{edu.courseName}</Text>
        <Text style={styles.cgpa}>{edu.cgpa}</Text>
        <Text
          style={styles.date}
        >{`${formattedStartDate} - ${formattedEndDate}`}</Text>
      </View>
      {isCandidate && !isBtnDisabled && (
        <View style={styles.editButtonContainer}>
          <Image
            src={editImg}
            style={styles.editButtonImage}
            onPress={() => handleEdit(edu._id)}
          />
        </View>
      )}
    </View>
  );
};

const EducationSection = ({
  education,
  editImg,
  isCandidate,
  isBtnDisabled,
  handleEdit,
}) => {
  return (
    <View style={styles.section}>
      <Text style={styles.header}>Education</Text>
      {education.map((edu, index) => (
        <EducationCard
          key={index}
          edu={edu}
          editImg={editImg}
          isCandidate={isCandidate}
          isBtnDisabled={isBtnDisabled}
          handleEdit={handleEdit}
        />
      ))}
    </View>
  );
};

export default EducationSection;

import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  section: {
    padding: 4,
    backgroundColor: "#FFFFFF",
    borderRadius: 2,
    marginBottom: 8
  },
  header: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    marginBottom: 8,
  },
  paragraph: {
    fontSize: 10,
    lineHeight: 1.5,
    marginBottom: 10,
    color: "#6B7280",
  },
  subHeader: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 4,
  },
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
});

const AboutSection = ({ about }) => {
  return (
    <View style={styles.section}>
      <Text style={styles.header}>About</Text>
      <Text style={styles.paragraph}>{about}</Text>
    </View>
  );
};

export default AboutSection;

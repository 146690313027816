import React, { useEffect, useState } from 'react';
import RadarChart from 'components/Charts/RadarChart';
import HorizontalBarChart from 'components/Charts/HorizontalBarChart';
import NoDataMessage from 'components/Shimmer/NoDataMessage';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { setResumeUpdate } from 'utils/resumeSlice';
const Dashboard = () => {
    const [popularity, setPopularity] = useState([]);
    const dispatch = useDispatch();
    const skills = useSelector((store) => store?.skills?.profileSkills);
    const skillsLowercased = Array.isArray(skills) ? skills.map(skill => skill.toLowerCase()) : [];
    useEffect(() => {
        if (skillsLowercased.length > 0) {
            fetchPopularity();
            const timer = setTimeout(() => {
                captureChartAsImage();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [skills]);
    const captureChartAsImage = () => {
        const chartElement1 = document.getElementById('dashboard');
        if (chartElement1) {
            html2canvas(chartElement1, { useCORS: true }).then((canvas) => {
                const imgData1 = canvas.toDataURL('image/png');
                console.log('Image 1 captured: ', imgData1);
                localStorage.setItem('dashboardImage', imgData1);
                console.log('Image 1 saved to localStorage');
                dispatch(setResumeUpdate({localStorage: imgData1}));
            });
            
        } else {
            console.log('One or both chart elements are missing.');
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            captureChartAsImage();
        }, 1000);
        return () => clearTimeout(timer);
    }, [popularity])
    const fetchPopularity = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API__URL}/api/growth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ skills: skillsLowercased }),
            });
            const result = await response.json();
            if (result?.data) {
                setPopularity(result.data);
            } else {
                console.error("No data returned from the API.");
            }
        } catch (error) {
            console.error("Error while fetching popularity:", error);
        }
    };
    if (!popularity.length) {
        return (
            <div className="hidden lg:flex justify-between bg-white p-6 rounded-md min-w-[50%] md:max-w-[98%]">
                <NoDataMessage />
                <div className="w-[1px] bg-gray-300 mx-4" />
                <NoDataMessage />
            </div>
        );
    }
    return (
        <div id="dashboard" className="hidden lg:flex flex justify-between bg-white p-6 rounded-md min-w-[50%] md:max-w-[98%]">
            <div id="dashboard1" className="flex-1 p-4">
                <HorizontalBarChart skills={popularity} />
            </div>
            <div className="w-[1px] bg-gray-300 mx-4" />
            <div id="dashboard2" className="flex-1 p-4">
                <RadarChart skills={popularity} />
            </div>
        </div>
    );
};
export default Dashboard;
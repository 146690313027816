import { createSlice } from "@reduxjs/toolkit";
const skillSlice = createSlice({
    name: "skills",
    initialState: {
        profileSkills: [],
    },
    reducers: {
        setProfileSkills: (state, action) => {
            state.profileSkills = action.payload;
        },
    },
});
export const { setProfileSkills } = skillSlice.actions;
export default skillSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logoCompany from "../../../images/companyLogo.png";
import AboutSection from "./AboutSection";
import WorkExperienceSection from "./WorkExperienceSection";
import ProjectSection from "./ProjectSection";
import SideBar from "./SideBar";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 2,
    backgroundColor: "#EEF6FF",
    fontFamily: "Helvetica",
    position: "relative",
    height: "100%",
  },
  header: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#007BFF",
    textAlign: "center",
    marginBottom: 4,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "600",
    color: "#333333",
    marginBottom: 4,
    textAlign: "center",
  },
  content: {
    fontSize: 10,
    color: "#333333",
    marginBottom: 4,
  },
  listItem: {
    fontSize: 14,
    color: "#555555",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    color: "#777777",
  },
  logo: {
    width: 80,
    height: 20,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 30,
  },
  flexColumn: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  contentSection: {
    width: "100%",
    paddingLeft: 10,
  },
  sideSection: {
    width: "50%",
  },
  footerContent: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    color: "#777777",
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0.1,
    width: "100%",
    height: "100%",
  },
  watermarkImage: {
    position: "absolute",
    width: 80,
    height: 40,
    opacity: 0.1,
  },
  dashboardImage: {
    width: "100%",
    height: 200,
    objectFit: "contain",
    padding: 0,
    margin: 0
  },
});
const Resume = ({ candidateData }) => {
  const dashboardImage = localStorage.getItem('dashboardImage') ?? null;
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.flexRow}>
          <View style={styles.sideSection}>
            <SideBar candidateData={candidateData} />
          </View>
          <View style={styles.contentSection}>
            {dashboardImage && (
              <Image src={dashboardImage} style={styles.dashboardImage} />
            )}
            <AboutSection about={candidateData?.about} />
            <WorkExperienceSection
              workExperience={candidateData.workExperience}
            />
            <ProjectSection projects={candidateData?.personalProject} />
          </View>
        </View>
        <View style={styles.footerContent}>
          <Image src={logoCompany} style={styles.logo} />
          <Text>© 2024 WorkCrew. All rights reserved.</Text>
        </View>
      </Page>
    </Document>
  );
};
export default Resume;
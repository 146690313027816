import React, { useEffect, useState } from "react";
import "../style.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import mixpanel from "../../mixpanel";
import { useAuth } from "utils/auth";
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";
import { whiteBox } from "components";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

function NewLogin({ isRecruiter, setIsRecruiter, setShowLoginPopup, setShowSignupPopup }) {
    // console.log("login", isRecruiter);
    const auth = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [inputValues, setInputValues] = useState({
        email: "",
        password: "",
        remail: "",
        rpassword: "",
    });
    const [userType, setUserType] = useState("candidate");

    const [res, setRes] = useState("");

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handlelogin = (e) => {
        e.preventDefault();


        axios
            .post(`${process.env.REACT_APP_API__URL}/api/candidate/login`, {
                email: inputValues.email,
                password: inputValues.password,
            })
            .then((res) => {
                mixpanel.track("Button Clicked", {
                    buttonName: "Login",
                  });
                console.log(res);
                setRes(res.data.message);
                handleClick();
                // console.log(res)
                if (res.status == 200) {
                    if (res.data.candidate.isProfileComplete == false) {
                        //   console.log("FALSE")
                        const authObject = {
                            userId: res.data.candidate._id,
                            token: res.data.token,
                            userType: "candidate",
                            candidateDone: false,
                        };
                        auth.login(authObject);
                        navigate("/candidate/profile");
                    } else {
                        const authObject = {
                            userId: res.data.candidate._id,
                            token: res.data.token,
                            userType: "candidate",
                            candidateDone: true,
                        };
                        auth.login(authObject);
                        navigate("/candidate/dashboard");
                    }
                } else {
                    setRes("Something went wrong");
                }
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    setRes("Invalid Password");
                    handleClick();
                } else {
                    setRes("Email not found");
                    handleClick();
                }
            });
    };

    const handlerlogin = (e) => {
        e.preventDefault();

        axios
            .post(`${process.env.REACT_APP_API__URL}/api/recruiter/login`, {
                email: inputValues.remail,
                password: inputValues.rpassword,
            })
            .then((res) => {
                // console.log(res);
                setRes(res.data.Message);
                handleClick();
                if (res.status == 200) {
                    console.log(auth);
                    console.log(res.data);
                    if (["admin", "super_admin"].indexOf(res.data.recruiter.role) > -1) {
                        console.log("admin");
                        const authObject = {
                            userId: res.data.recruiter._id,
                            token: res.data.token,
                            userType: res.data.recruiter.role,
                            companyDone: true,
                        };
                        auth.login(authObject);
                        navigate("/admin/dashboard");
                        console.log("admin");
                    } else if (
                        res.data.recruiter.company.isCompanyProfileComplete == false
                    ) {
                        console.log("FALSE");
                        const authObject = {
                            userId: res.data.recruiter._id,
                            token: res.data.token,
                            userType: "recruiter",
                            companyDone: false,
                            isSubscriptionActive: res.data.recruiter?.isSubscriptionActive,
                            subscriptionValidTill:
                                res.data.recruiter?.subscriptionPlan?.validTill,
                        };
                        auth.login(authObject);

                        navigate("/company/profile");
                        console.log("IMAGE");
                    } else {
                        console.log("TRUE");
                        const authObject = {
                            userId: res.data.recruiter._id,
                            token: res.data.token,
                            userType: "recruiter",
                            companyDone: true,
                            isSubscriptionActive: res.data.recruiter?.isSubscriptionActive,
                            subscriptionValidTill:
                                res.data.recruiter?.subscriptionPlan?.validTill,
                        };
                        auth.login(authObject);

                        navigate("/recruiter/dashboard");
                        console.log("IMAGE");
                    }

                } else {
                    setRes(res.data.Message);
                    console.log(res);
                }
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status == 401) {
                    setRes("Invalid Password");
                    handleClick();
                } else {
                    setRes("Email not found");
                    handleClick();
                }

            });
    };
    const [i, setI] = useState(0);

    const handleCandidate = (e) => {
        e.preventDefault();
        setUserType("candidate")
        setInputValues({ ...inputValues, remail: "", rpassword: "" });
        setI(0);
        console.log(inputValues);
        console.log(i);
    };

    const handleRecruiter = (e) => {
        e.preventDefault();
        setUserType("recruiter")
        setI(1);
        setInputValues({ ...inputValues, email: "", password: "" });
        // console.log(inputValues);
        // console.log(i);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(inputValues);
        if (i === 0) {
            handlelogin(e);
        } else {
            handlerlogin(e);
        }
    };
    // console.log(auth)
    if (auth.userType === "admin") {
        return <Navigate to="/admin/dashboard" />;
    }
    if (auth.userType === "candidate" && auth.candidateDone === "false") {
        return <Navigate to="/candidate/profile" />;
    }
    if (auth.userType === "candidate" && auth.candidateDone === "true") {
        return <Navigate to="/candidate/dashboard" />;
    }
    if (auth.userType === "recruiter" && auth.companyDone === "true") {
        return <Navigate to="/recruiter/dashboard" />;
    }
    if (auth.userType === "recruiter" && auth.companyDone === "false") {
        return <Navigate to="/company/profile" />;
    }

    const handleCancel = () => {
        setShowLoginPopup(false);
    };

    const handleSignup = () => {
        setShowLoginPopup(false);
        setShowSignupPopup(true)
    };


    const handleGoogle = async (credentialResponse) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API__URL}/api/google-auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ token: credentialResponse.credential, userType }),
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate');
            }

            const datas = await response.json();
            setRes(datas.message)
            const authObject = {
                userId: datas?.data?._id,
                token: datas?.token,
                userType: "candidate",
                candidateDone: datas?.data?.isProfileComplete ?? false,
            };

            auth.login(authObject);
            if (datas?.data?.isProfileComplete === false) {
                navigate("/candidate/profile");
            } else {
                navigate("/candidate/dashboard");
            }
            handleClick();
        } catch (error) {
            console.error('Error during Google authentication:', error);
            setRes(error.message);
        }
    };

    return (
        <GoogleOAuthProvider clientId="513027127856-i5ai43i0308cileubcresjjhua4jaq5q.apps.googleusercontent.com">
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.2)] backdrop-blur-sm z-30">
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={res === "Succesfully Loggedin" ? "success" : "error"}
                        sx={{ width: "100%" }}
                    >
                        {res}
                    </Alert>
                </Snackbar>
                <div className="flex justify-center flex-col px-8 py-2 bg-white md:rounded-[20px] w-full md:w-[426px] shadow-lg h-full md:h-[650px]">
                    <div className="flex mb-2">
                        <h1 className="grow pl-4 font-sans text-[18px] text-primarycolor text-center">Welcome back!</h1>
                        <button
                            className="cursor-pointer text-[24px] border-none text-mediumblue bg-transparent"
                            onClick={handleCancel}
                        >
                            x
                        </button>
                    </div>
                    <div className="flex justify-between">
                        <button
                            className={`rounded-[20px] cursor-pointer text-[14px] w-[45%] h-[47px] border-none ${i === 0 ? 'bg-primarycolor text-white' : 'bg-lightblue text-black'
                                }`}
                            onClick={handleCandidate}
                        >
                            Candidate
                        </button>
                        <button
                            className={`rounded-[20px] cursor-pointer text-[14px] w-[45%] h-[47px] border-none ${i === 1 ? 'bg-primarycolor text-white' : 'bg-lightblue text-black'
                                }`}
                            onClick={handleRecruiter}
                        >
                            Recruiter
                        </button>

                    </div>
                    {i === 0 ? (
                        <form onSubmit={handleSubmit}>

                            <label for="emailid"
                                className="font-sans  text-[14px] leading-7	pl-5"
                            >
                                Email
                            </label>
                            <br></br>
                            <input
                                type="email"
                                id="emailid"
                                name="emailid"
                                required
                                className="rounded-[23px] bg-lightblue w-full border-none h-[44px] md:h-[54px] mb-4 px-5 box-border"

                                value={inputValues.email}
                                onChange={(e) =>
                                    setInputValues({ ...inputValues, email: e.target.value })
                                }
                            />
                            <br></br>

                            <label for="userpassword"
                                className="font-sans  text-[14px] leading-7	pl-5"
                            >
                                Password
                            </label>
                            <br></br>
                            <input
                                type="password"
                                id="userpassword"
                                name="userpassword"
                                required
                                className="rounded-[23px] bg-lightblue w-full border-none h-[44px] md:h-[54px] mb-4 px-5 box-border"

                                value={inputValues.password}
                                onChange={(e) =>
                                    setInputValues({ ...inputValues, password: e.target.value })
                                }
                            />
                            <br></br>

                            <div className="flex justify-between px-4 md:px-4">
                                <div className="flex mb-[24px] items-center">
                                    <input
                                        type="checkbox"
                                        className="h-[15px] w-[15px] border-1 border-[#9F9F9F] cursor-pointer rounded-[20px]"
                                        id="loggedin"
                                        checked={inputValues.candidateChecked}
                                        onChange={(e) => {
                                            setInputValues({
                                                ...inputValues,
                                                candidateChecked: e.target.checked,
                                            });
                                        }}
                                    />
                                    <label
                                        for="loggedin"
                                        className="font-sans text-[12px] leading-7 cursor-pointer text-[#9F9F9F]"
                                    >
                                        Keep me signed in
                                    </label>
                                </div>
                                <Link to="/reset/password">
                                    <div className="font-sans text-[12px] leading-7 cursor-pointer text-[#9F9F9F]">
                                        Forgot Password?
                                    </div>
                                </Link>
                            </div>

                            <div className="flex items-center">
                                <hr className="flex-grow border-t border-gray-100" />
                                <span className="mx-4 text-gray-500">OR</span>
                                <hr className="flex-grow border-t border-gray-100" />
                            </div>

                            <div className="w-full flex items-center justify-center h-12 mt-4 mb-4 rouned-md hover:bg-gray-100">
                                <GoogleLogin onSuccess={handleGoogle} onFailure={() => console.log("Google Login Error")} />
                            </div>

                            <div className="md:mt-8 flex items-center flex-col space-y-0">
                                <button className="hover:bg-[#4892E8] cursor-pointer rounded-3xl text-[14px] w-[167px] md:w-[187px] h-[43px] md:h-[53px] md:py-[13px] md:px-[75px] border-none bg-primarycolor text-white"
                                    type="submit">
                                    Login
                                </button>
                                <div className="flex items-center justify-center">
                                    <p className="text-[12px] leading-7 text-[#9F9F9F] pr-1">Don't have an account?</p>
                                    <span
                                        onClick={handleSignup}
                                        className="cursor-pointer text-[12px] leading-7 text-primarycolor"
                                    >
                                        Signup
                                    </span>
                                </div>
                            </div>

                        </form>
                    ) : (
                        <form onSubmit={handleSubmit}>

                            <label for="remailid" className="font-sans  text-[14px] leading-7	pl-5"
                            >
                                Email
                            </label>
                            <br></br>
                            <input
                                type="email"
                                id="remailid"
                                name="remailid"
                                required
                                className="rounded-[23px] bg-lightblue w-full border-none h-[44px] md:h-[54px] mb-4 px-5 box-border"

                                value={inputValues.remail}
                                onChange={(e) =>
                                    setInputValues({ ...inputValues, remail: e.target.value })
                                }
                            />
                            <br></br>

                            <label for="ruserpassword" className="font-sans  text-[14px] leading-7	pl-5"
                            >
                                Password
                            </label>
                            <br></br>
                            <input
                                type="password"
                                id="ruserpassword"
                                name="ruserpassword"
                                required
                                className="rounded-[23px] bg-lightblue w-full border-none h-[44px] md:h-[54px] mb-4 px-5 box-border"

                                value={inputValues.rpassword}
                                onChange={(e) =>
                                    setInputValues({ ...inputValues, rpassword: e.target.value })
                                }
                            />
                            <br></br>
                            <div className="flex justify-between px-3 md:px-4">
                                <div className="flex mb-[24px] items-center">
                                    <input
                                        type="checkbox"
                                        className="md:h-[15px] md:w-[15px] h-[14px] w-[14px] border-1 border-[#9F9F9F] cursor-pointer rounded-[20px]"
                                        id="loggedin"
                                        checked={inputValues.recruiterChecked}
                                        onChange={(e) => {
                                            setInputValues({
                                                ...inputValues,
                                                recruiterChecked: e.target.checked,
                                            });
                                        }}
                                    />
                                    <label
                                        for="loggedin"
                                        className="font-sans text-[11.5px] md:text-[12px] leading-7 cursor-pointer text-[#9F9F9F]"
                                    >
                                        Keep me signed in
                                    </label>
                                </div>
                                <Link to="/reset/password">
                                    <div className="font-sans text-[11.5px] md:text-[12px] leading-7 cursor-pointer text-[#9F9F9F]">
                                        Forgot Password?
                                    </div>
                                </Link>
                            </div>
                            <div className="md:mt-8 flex items-center flex-col space-y-0">
                                <button className="hover:bg-[#4892E8] cursor-pointer rounded-3xl text-[14px] w-[167px] md:w-[187px] h-[43px] md:h-[53px] md:py-[13px] md:px-[75px] border-none bg-primarycolor text-white"
                                    type="submit">
                                    Login
                                </button>
                                <div className="flex items-center justify-center">
                                    <p className="text-[12px] leading-7 text-[#9F9F9F] pr-1">Don't have an account?</p>
                                    <span

                                        onClick={handleSignup}
                                        className="cursor-pointer text-[12px] leading-7 text-primarycolor"
                                    >
                                        Signup
                                    </span>
                                </div>
                            </div>

                        </form>
                    )}
                </div>
            </div>
        </GoogleOAuthProvider>
    );
}

export default NewLogin;

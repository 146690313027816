import React from "react";
import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";
const BTN_SEE_ALL = "See All";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EFF6FF",
    padding: 4,
    marginBottom: 4,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 2,
  },
  headerText: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    color: "#F97316",
    marginRight: 8,
  },
  heading: {
    fontSize: 10,
    fontWeight: "medium",
    color: "#374151",
  },
  count: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#4B5563",
  },
  badgeContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    gap: 15,
    textAlign: "center",
  },
  badge: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  badgeImage: {
    width: 40,
    height: 40,
  },
  badgeCount: {
    marginTop: 5,
    fontSize: 12,
    color: "#374151",
    textAlign: "center",
  },
  badgeTitle: {
    fontSize: 10,
    color: "#6B7280",
    textAlign: "center",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: 20,
  },
  button: {
    backgroundColor: "#3B82F6",
    color: "#FFFFFF",
    padding: "6px 12px",
    borderRadius: 4,
    fontSize: 12,
    textAlign: "center",
    width: 100,
    margin: "auto",
  },
  buttonHover: {
    backgroundColor: "#2563EB",
  },
});

const ProfileBadgesPDF = ({ head, count, badges }) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerText}>
          <Text style={styles.heading}>{head}</Text>
        </View>
        <Text style={styles.count}>{count}</Text>
      </View>
      <View style={styles.badgeContainer}>
        {badges.map((badge, index) => (
          <View key={index} style={styles.badge}>
            <Image src={badge.img} style={styles.badgeImage} />
            <Text style={styles.badgeCount}>{badge.count}</Text>
            <Text style={styles.badgeTitle}>{badge.title}</Text>
          </View>
        ))}
      </View>
      <View style={styles.buttonContainer}>
        <Text style={styles.button}>{BTN_SEE_ALL}</Text>
      </View>
    </View>
  );
};

export default ProfileBadgesPDF;

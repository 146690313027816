import React, { useEffect, useState } from "react";
import "../style.css";
import axios from "axios";
import mixpanel from '../../mixpanel';
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "utils/auth";
import { useSearchParams } from "react-router-dom";
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

function NewSignup({ setIsRecruiter, isRecruiter, setShowSignupPopup, setShowLoginPopup }) {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const alertCode = searchParams.get("alertCode");
  const [errorOpenPublic, setErrorOpenPublic] = useState(false);
  const [i, setI] = useState(0);
  const [userType, setUserType] = useState("candidate");
  const navigate = useNavigate();


  useEffect(() => {
    if (alertCode === "1") {
      setErrorOpenPublic(true);
    }
  }, [alertCode]);

  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    username: "",
    rphone: "",
    rpassword: "",
    remail: "",
    rcompany: "",
    candidateChecked: false,
    recruiterChecked: false,
  });

  const [open, setOpen] = React.useState(false);

  const [res, setRes] = useState("");

  const handleClick = () => {
    setOpen(true);
  };
  const onErrorPublic = () => {
    setErrorOpenPublic(true);
  };
  const redirect = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpenPublic(false);
    setOpen(false);
    // redirect when user successfull register account
    if (
      res === "Candidate is created successfully" ||
      res === "Recruiter is created successfully"
    ) {
      redirect("/login"); // redirect to login page
    }
  };
  const handleRecruiterSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    if (inputValues.rpassword.length <= 5) {
      setRes("Password should be atleast 6 digit");
      handleClick();
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/recruiter`, {
        name: inputValues.username,
        phone: inputValues.rphone,
        email: inputValues.remail,
        password: inputValues.rpassword,
        userType: "recruiter",
        companyName: inputValues.rcompany,
      })
      .then((res) => {
        console.log(res);
        setRes(res.data.message);
        handleClick();
        // setIsRecruiter(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setRes("Email already exists");
          handleClick();
        } else {
          setRes("Something went wrong");
          handleClick();
        }
      });
  };

  const handleCandidateSubmit = (e) => {
    e.preventDefault();
    if (inputValues.password !== inputValues.cpassword) {
      setRes("Passwords do not match");
      handleClick();
      return;
    } else if (inputValues.password.length <= 5) {
      setRes("Password should be atleast 6 digit");
      handleClick();
      return;
    }
    console.log(inputValues);
    console.log("candidate submit");
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/candidate`, {
        name: inputValues.name,
        email: inputValues.email,
        password: inputValues.password,
        userType: "candidate",
      })
      .then((res) => {
        mixpanel.track("Button Clicked", {
          buttonName: "Sign Up",
        });
        console.log(res);
        setRes(res.data.message);
        handleClick();
        // redirect("/login");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setRes("Email already exists");
          handleClick();
        } else {
          setRes("Something went wrong");
          handleClick();
        }
      });
  };

  // const [i, setI] = useState(0);

  const handleCandidate = (e) => {
    setUserType("candidate")
    e.preventDefault();
    setInputValues({
      ...inputValues,
      username: "",
      rphone: "",
      rpassword: "",
      remail: "",
      rcompany: "",
      recruiterChecked: false,
    });
    setI(0);
    // console.log(i);
    // console.log(inputValues);
  };

  const handleRecruiter = (e) => {
    setUserType("recruiter")
    e.preventDefault();
    setInputValues({
      ...inputValues,
      name: "",
      email: "",
      password: "",
      cpassword: "",
      candidateChecked: false,
    });

    setI(1);
    // console.log(i);
    // console.log(inputValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    if (i === 0) {
      if (inputValues.candidateChecked) {
        handleCandidateSubmit(e);
      } else {
        setRes("Please agree to the terms and conditions");
        // redirect("/login");
        handleClick();
      }
    } else {
      if (inputValues.recruiterChecked) {
        handleRecruiterSubmit(e);
      } else {
        setRes("Please agree to the terms and conditions");
        handleClick();
      }
    }
  };


  if (auth.userType === "candidate" && auth.candidateDone === false) {
    console.log("user auth us" + auth)
    return <Navigate to="/candidate/profile" />;
  }
  if (auth.userType === "candidate" && auth.candidateDone === true) {
    return <Navigate to="/candidate/dashboard" />;
  }

  if (auth.userType === "recruiter") {
    return <Navigate to="/recruiter/dashboard" />;
  }


  const handleCancel = () => {
    setShowSignupPopup(false);
  };

  const handleLogin = () => {
    setShowSignupPopup(false)
    setShowLoginPopup(true);
  };


  const handleGoogle = async (credentialResponse) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API__URL}/api/google-auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token: credentialResponse.credential, userType }),
      });

      if (!response.ok) {
        throw new Error('Failed to authenticate');
      }

      const datas = await response.json();
      const authObject = {
        userId: datas?.data?._id,
        token: datas?.token,
        userType: "candidate",
        candidateDone: datas?.data?.isProfileComplete ?? false,
      };

      auth.login(authObject);
      if (datas?.data?.isProfileComplete === false) {
        navigate("/candidate/profile");
      } else {
        console.log('Navigating to dashboard');
        navigate("/candidate/dashboard");
      }
      setRes(datas.message)
      handleClick();
    } catch (error) {
      console.error('Error during Google authentication:', error);
      setRes(error.message);
    }
  };



  return (
    <GoogleOAuthProvider clientId="513027127856-i5ai43i0308cileubcresjjhua4jaq5q.apps.googleusercontent.com">
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.2)] backdrop-blur-sm z-30">
        {/*  */}
        <Snackbar
          open={errorOpenPublic}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
            Please Sign Up before Applying to Job
          </Alert>
        </Snackbar>
        <Snackbar
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={
              res === "Candidate is created successfully" ||
                res === "Recruiter is created successfully"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {res}
          </Alert>
        </Snackbar>
        <div className={`flex justify-center flex-col bg-white px-8 py-2 md:rounded-[20px] w-full md:w-[426px] shadow-lg ${i === 0 ? 'h-full md:h-[93%] 2xl:h-[706px]' : 'h-full md:h-[96%] 2xl:h-[746px]'}`}>
          <div className="flex">
            <h1 className="grow pl-4 font-sans text-[18px] text-primarycolor text-center">Create Account</h1>
            <button
              className="cursor-pointer text-[24px] border-none text-mediumblue bg-transparent"
              onClick={handleCancel}
            >
              x
            </button>
          </div>

          <div className="flex justify-between">
            <button
              className={`rounded-[20px] cursor-pointer text-[14px] w-[45%] h-[40px] border-none ${i === 0 ? 'bg-primarycolor text-white' : 'bg-lightblue text-black'
                }`}
              onClick={handleCandidate}
            >
              Candidate
            </button>
            <button
              className={`rounded-[20px] cursor-pointer text-[14px] w-[45%] h-[40px] border-none ${i === 1 ? 'bg-primarycolor text-white' : 'bg-lightblue text-black'
                }`}
              onClick={handleRecruiter}
            >
              Recruiter
            </button>

          </div>
          {i === 0 ? (
            <form onSubmit={handleSubmit}>
              <label for="name"
                className="font-sans  text-[14px] leading-7	pl-5"
              >
                Name
              </label>
              <br></br>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.name}
                onChange={(e) =>
                  setInputValues({ ...inputValues, name: e.target.value })
                }
              />
              <br></br>

              <label for="emailid"
                className="font-sans  text-[14px] leading-7	pl-5"
              >
                Email
              </label>
              <br></br>
              <input
                type="email"
                id="emailid"
                name="emailid"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.email}
                onChange={(e) =>
                  setInputValues({ ...inputValues, email: e.target.value })
                }
              />
              <br></br>

              <label for="userpassword"
                className="font-sans  text-[14px] leading-7	pl-5"
              >
                Password
              </label>
              <br></br>
              <input
                type="password"
                id="userpassword"
                name="userpassword"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.password}
                onChange={(e) =>
                  setInputValues({ ...inputValues, password: e.target.value })
                }
              />
              <br></br>

              <label for="usercpassword" className="font-sans  text-[14px] leading-7	pl-5"
              >
                Confirm Password
              </label>
              <br></br>
              <input
                type="password"
                id="usercpassword"
                name="usercpassword"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.cpassword}
                onChange={(e) =>
                  setInputValues({ ...inputValues, cpassword: e.target.value })
                }
              />
              <br></br>

              <div className="flex items-center my-4">
                <hr className="flex-grow border-t border-gray-100" />
                <span className="mx-4 text-gray-500">OR</span>
                <hr className="flex-grow border-t border-gray-100" />
              </div>

              <div className="w-full flex items-center justify-center h-12 mb-4 rouned-md hover:bg-gray-100">
                <GoogleLogin onSuccess={handleGoogle} onFailure={() => console.log("Google Login Error")} />
              </div>



              <div className="flex mb-[24px] items-center pl-8">
                <input
                  type="checkbox"
                  className="h-[15px] w-[15px] border-1 border-[#9F9F9F] cursor-pointer rounded-[20px]"
                  id="loggedin"
                  checked={inputValues.candidateChecked}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      candidateChecked: e.target.checked,
                    });
                  }}
                />
                <label
                  for="loggedin"
                  className="font-sans text-[12px] leading-7 cursor-pointer text-[#9F9F9F]"
                >
                  You accept our terms and services
                </label>
              </div>
              <div className="flex items-center flex-col space-y-0">
                <button className="hover:bg-[#4892E8] cursor-pointer rounded-3xl text-[14px] w-[167px] md:w-[187px] h-[43px] md:h-[53px] md:py-[13px] md:px-[75px] border-none bg-primarycolor text-white"
                  type="submit">
                  Signup
                </button>
                <div className="flex items-center justify-center h-[32px]">
                  <p className="text-[12px] leading-7 text-[#9F9F9F] pr-1">Don't have an account?</p>
                  <span

                    onClick={handleLogin}
                    className="cursor-pointer text-[12px] leading-7 text-primarycolor"
                  >
                    Login
                  </span>
                </div>
              </div>

            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <label for="rusername" className="font-sans  text-[14px] leading-7	pl-5"
              >
                Username
              </label>
              <br></br>
              <input
                type="text"
                id="rusername"
                name="rusername"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.username}
                onChange={(e) =>
                  setInputValues({ ...inputValues, username: e.target.value })
                }
              />
              <br></br>

              <label for="rphone" className="font-sans  text-[14px] leading-7	pl-5"
              >
                Mobile Number
              </label>
              <br></br>
              <input
                type="tel"
                id="rphone"
                name="rphone"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.rphone}
                onChange={(e) =>
                  setInputValues({ ...inputValues, rphone: e.target.value })
                }
              />
              <br></br>

              <label for="company" className="font-sans  text-[14px] leading-7	pl-5"
              >
                Company
              </label>
              <br></br>
              <input
                type="text"
                id="company"
                name="company"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.rcompany}
                onChange={(e) =>
                  setInputValues({ ...inputValues, rcompany: e.target.value })
                }
              />
              <br></br>

              <label for="remailid" className="font-sans  text-[14px] leading-7	pl-5"
              >
                Email
              </label>
              <br></br>
              <input
                type="email"
                id="remailid"
                name="remailid"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.remail}
                onChange={(e) =>
                  setInputValues({ ...inputValues, remail: e.target.value })
                }
              />
              <br></br>

              <label for="ruserpassword" className="font-sans  text-[14px] leading-7	pl-5"
              >
                Password
              </label>
              <br></br>
              <input
                type="password"
                id="ruserpassword"
                name="ruserpassword"
                required
                className="px-5 box-border rounded-[23px] bg-lightblue w-full border-none h-[40px] mb-1"

                value={inputValues.rpassword}
                onChange={(e) =>
                  setInputValues({ ...inputValues, rpassword: e.target.value })
                }
              />
              <br></br>
              <div className="flex mb-[20px] items-center pl-8">
                <input
                  type="checkbox"
                  className="h-[15px] w-[15px] border-1 border-[#9F9F9F] cursor-pointer rounded-[27px]"
                  id="loggedin"
                  checked={inputValues.recruiterChecked}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      recruiterChecked: e.target.checked,
                    });
                  }}
                />
                <label
                  for="loggedin"
                  className="font-sans text-[12px] leading-7 cursor-pointer text-[#9F9F9F]"
                >
                  You accept our terms and services
                </label>
              </div>
              <div className="flex items-center flex-col">
                <button className="hover:bg-[#4892E8] cursor-pointer rounded-3xl text-[14px] w-[167px] md:w-[187px] h-[43px] md:py-[13px] md:px-[75px] border-none bg-primarycolor text-white"
                  type="submit">
                  Signup
                </button>
                <div className="flex items-center justify-center h-[32px]">
                  <p className="text-[12px] leading-7 text-[#9F9F9F] pr-1">Don't have an account?</p>
                  <span

                    onClick={handleLogin}
                    className="cursor-pointer text-[12px] leading-7 text-primarycolor"
                  >
                    Login
                  </span>
                </div>
              </div>

            </form>
          )}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}
export default NewSignup;

import { configureStore } from "@reduxjs/toolkit";
import candidateReducer from "./candidateSlice";
import btnsReducer from "./btnsSlice";
import skillReducer from "./skillSlice";
import resumeReducer from "./resumeSlice";
const appStore = configureStore({
    reducer: {
        candidate: candidateReducer,
        btns: btnsReducer,
        skills: skillReducer,
        resume: resumeReducer
    }
})
export default appStore;
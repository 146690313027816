import React, { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadarController,
    RadialLinearScale,
    PointElement,
    LineElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadarController,
    RadialLinearScale,
    PointElement,
    LineElement
);

const RadarChart = ({ skills }) => {
    const data = {
        labels: skills.map((skill) => skill.name),
        datasets: [
            {
                label: "Skill Popularity",
                data: skills.map((skill) => skill.popularity),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 2,
                pointBackgroundColor: "rgba(54, 162, 235, 1)",
                pointBorderColor: "#fff",
                pointBorderWidth: 3,
                pointRadius: 5,
            },
            {
                label: "Reference",
                data: [100, 100, 100, 100, 100],
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
                pointBackgroundColor: "rgba(255, 99, 132, 1)",
                pointBorderColor: "#fff",
                pointRadius: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: 100,
                grid: {
                    color: "rgba(204, 204, 204, 0.5)",
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        const skillName = tooltipItem.label;
                        const popularity = tooltipItem.raw;
                        return `${skillName}: ${popularity}%`;
                    },
                },
            },
        },
    };

    return (
        <div
            style={{
                backgroundColor: "white",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "300px",
                margin: "auto",
                boxSizing: "border-box",
            }}
        >
            {skills.length > 0 ? (
                <Radar data={data} options={options} />
            ) : (
                <p style={{ textAlign: "center", color: "#999" }}>
                    Please select a skill to view its popularity.
                </p>
            )}
        </div>
    );
};


export default RadarChart;
import React from 'react'
import {Helmet} from 'react-helmet'
// import { Helmet } from 'react-helmet-async';
import banner from 'images/banner.png'
const DOMAIN =window.origin
const MAIN_KEYWORDS = ""

const DEFAULT_IMAGE_CARD=banner
const DEFAULT_TITLE="Find Jobs & Careers - Job Portal | Workcrew"
const DEFAULT_DESCRIPTION="Find your dream job with ease at YourCompany's job portal. Browse job listings in tech, marketing, finance, and more. Start your job search today!"
const FAVICON_SOURCE=""
const POSTFIX_TITLE=" - WorkCrew"
const Helmet1 = ({
    title = DEFAULT_TITLE,
    description = DEFAULT_DESCRIPTION, 
    link=window.location.pathname ,
    keywords = "",
    imageCard = DEFAULT_IMAGE_CARD,
    largeTwitterCard,
    addPostfixTitle,
    noIndex,
    children    
}) => {
    let metaTitle

    if(addPostfixTitle){
        metaTitle = `${title} ${POSTFIX_TITLE}`
    }else {
        metaTitle = title
    }

    const metaDescription = description || DEFAULT_DESCRIPTION
    const metaLink =  DOMAIN +link
    const metaKeywords = keywords.length > 0 ?  MAIN_KEYWORDS + ", " + keywords : MAIN_KEYWORDS
    let MetaImageCard 

    if(imageCard.startsWith("https")){
        MetaImageCard = imageCard
    }else {
        MetaImageCard = DOMAIN + imageCard
    }
    const metaRobots = noIndex ? "noindex, nofollow" : "index, follow"
    const twitterCardType = largeTwitterCard ? "summary_large_image" : "summary"
  return (
    <Helmet>
        <html lang='en' />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={metaLink} />
        <meta name="keywords" content={"job portal, job listings, careers, job search, find jobs, job opportunities, apply for jobs, technology jobs, marketing jobs"} />
        <meta name="robots" content={metaRobots} />

        {/* OG TAGS */}
        {/* {console.log("banner", MetaImageCard)}
        {console.log(banner)} */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={metaLink} />
        <meta property="og:image" content={MetaImageCard} />
        <meta property="og:type" content="website" />

        {/* Twitter Tags  */}
        <meta name="twitter:card" content={twitterCardType} />
        <meta name="twitter:site" content="@workcrew" />
        <meta name="twitter:creator" content="@workcrew" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={MetaImageCard} />

        <meta name='referrer' content='origin-when-cross-origin' />
    </Helmet>
  )
}

export default Helmet1
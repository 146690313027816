import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MdDelete } from "react-icons/md";
import dayjs from 'dayjs';
import { TextField } from "@mui/material";

const AddEducation = ({
  candidateData,
  handleEducation,
  editEducationData,
  handleDelete,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const collegeRef = useRef(null);
  const courseRef = useRef(null);
  const cgpaRef = useRef(null);
  const [errors, setErrors] = useState({
    collegeName: "",
    courseName: "",
    cgpa: "",
  });

  useEffect(() => {
    setFormattedStartDate(dayjs(startDate).format('MM/DD/YYYY'));
    setFormattedEndDate(dayjs(setEndDate).format('MM/DD/YYYY'));
  }, [startDate, endDate]);

  useEffect(() => {
    if (editEducationData.length > 0) {
      const { collegeName, courseName, cgpa, startDate, endDate } =
        editEducationData[0];
      collegeRef.current.value = collegeName;
      courseRef.current.value = courseName;
      cgpaRef.current.value = cgpa;
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
    }
  }, [editEducationData]);

  const handleForm = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    let newErrors = { collegeName: "", courseName: "", cgpa: "" };

    if (!collegeRef.current.value) {
      formIsValid = false;
      newErrors.collegeName = "Institute name is required";
    }
    if (!courseRef.current.value) {
      formIsValid = false;
      newErrors.courseName = "Degree is required";
    }
    if (!cgpaRef.current.value || isNaN(cgpaRef.current.value)) {
      formIsValid = false;
      newErrors.cgpa = "Valid CGPA is required";
    }

    setErrors(newErrors);

    if (formIsValid) {
      const educationObj = addEducationDetails();
      if (editEducationData.length > 0) {
        await patchEducationField(educationObj, editEducationData[0]._id);
      } else {
        await patchEducationField(educationObj);
      }
    }
  };

  const addEducationDetails = () => {
    const eduObj = {
      courseName: courseRef.current.value,
      collegeName: collegeRef.current.value,
      cgpa: cgpaRef.current.value,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    courseRef.current.value = "";
    collegeRef.current.value = "";
    cgpaRef.current.value = "";
    setStartDate(new Date());
    setEndDate(new Date());
    return eduObj;
  };

  const patchEducationField = async (educationObj, id) => {
    const patchData = id
      ? {
        education: candidateData.education.map((edu) =>
          edu._id === id ? educationObj : edu
        ),
      }
      : { education: [...candidateData.education, educationObj] };

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API__URL}/api/candidate/${candidateData._id}`,
        patchData
      );
      handleEducation(educationObj);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleForm}>
        <div className="bg-blue-50 p-8 shadow-md mx-5 mb-20">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold mb-4">Education Details</h2>
            {editEducationData && (
              <MdDelete
                className="ml-2 cursor-pointer text-red-500"
                onClick={() => handleDelete(editEducationData[0]._id)}
              />
            )}
          </div>
          <div className="px-5">
            <div className="mb-4">
              <label className="block mb-2">Add Institute Name</label>
              <input
                type="text"
                ref={collegeRef}
                placeholder="Enter Institute"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
              />
              {errors.collegeName && (
                <p className="text-red-500">{errors.collegeName}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-2">Add Degree</label>
              <input
                ref={courseRef}
                type="text"
                placeholder="Enter Degree"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
              />
              {errors.courseName && (
                <p className="text-red-500">{errors.courseName}</p>
              )}
            </div>
            <div className="mb-4 flex justify-between">
              <div className="mb-4">
                <label className="block mb-2">Start Date</label>
                <MobileDatePicker
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={formattedStartDate}
                      className="border p-2 bg-white w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">End Date</label>
                <MobileDatePicker
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={formattedEndDate}
                      className="border p-2 w-full  bg-white border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Add CGPA</label>
              <input
                ref={cgpaRef}
                type="text"
                placeholder="Eg: 7"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
              />
              {errors.cgpa && <p className="text-red-500">{errors.cgpa}</p>}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={`ml-8 w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 mt-5
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </LocalizationProvider>
  );
};

export default AddEducation;
